export const GetSectionCss = (css, sectionName) => {
  let sectionStartTag = `/*----[[${sectionName}]]---*/`;
  let sectionEndTag = `/*----${sectionName}----*/`;

  let temp = css.split(sectionStartTag);
  if (temp && temp.length >= 2) {
    temp = temp[1].split(sectionEndTag);
    if (temp && temp.length > 0) {
      temp = temp[0];
    } else {
      temp = null;
    }
  } else {
    temp = null;
  }

  return temp;
};

export const GetSectionJS = (js, sectionName) => {
  let sectionStartTag = `/*----[[${sectionName}]]---*/`;
  let sectionEndTag = `/*----${sectionName}----*/`;

  let temp = js.split(sectionStartTag);
  if (temp && temp.length >= 2) {
    temp = temp[1].split(sectionEndTag);
    if (temp && temp.length > 0) {
      temp = temp[0];
    } else {
      temp = null;
    }
  } else {
    temp = null;
  }

  return temp;
};
