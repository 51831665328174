export const _set_token = (token) => {
  sessionStorage.setItem("token", token);
};

export const _remove_token = () => {
  sessionStorage.removeItem("token");
};

export const _set_is_dev = (status) => {
  sessionStorage.setItem("is_dev", status);
};

export const _is_dev = () => {
  return JSON.parse(sessionStorage.getItem("is_dev"));
};

export const _remove_is_dev = () => {
  sessionStorage.removeItem("is_dev");
};

export const _set_user = (user) => {
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const _get_user = () => {
  return JSON.parse(sessionStorage.getItem("user"));
};

export const _remove_user = () => {
  sessionStorage.removeItem("user");
};
