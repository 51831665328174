import { invokeApi } from "../../bl_libs/invokeApi";

export const _making_zip_of_project = (data) => {
  let requestObj = {
    path: "/api/project/make_zip_file_of_project",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  requestObj["postData"] = data;

  return invokeApi(requestObj);
};
