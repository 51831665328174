import { SnackbarProvider } from "notistack";
import { Slide } from "@mui/material";
// routes
import Routers from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { ContextPGIMode } from "./hooks/PGIModeContext";
import { ContextBuilder } from "./hooks/builder-context/BuilderContext";
import "./assets/css/fonts.css";
import "./assets/css/style.css";
import "./assets/css/main.css";
import "./assets/css/editor_style.css";
import "./assets/css/ck_content.css";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ContextPGIMode>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <SnackbarProvider
          disableWindowBlurListener
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={3}
        >
          <ContextBuilder>
            <Routers />
          </ContextBuilder>
        </SnackbarProvider>
      </ThemeConfig>
    </ContextPGIMode>
  );
}
